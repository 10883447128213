import React from "react";
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';

function BigCalendar({ milestones, projects }) {
  const localizer = dayjsLocalizer(dayjs);

  // Define the accessors for start, end, title, and custom details
  const startAccessor = 'due_date';
  const endAccessor = 'due_date';
  const titleAccessor = 'title';
  const detailsAccessor = 'details'; // Custom accessor for additional details

  // Map milestones to the required format
  const myEventsList = milestones.map(milestone => ({
    ...milestone,
    start: milestone.due_date,
    end: milestone.due_date,
    title: milestone.name,
    details: milestone.project_name, // Adding custom details property
  }));

  const handleSelectEvent = (event, e) => {
    console.log('Selected Event:', event);
    console.log(projects[0])
    // You can access additional details with event.details
    // You can perform additional actions when an event is clicked
  };

  // Function to generate events based on project filtering
  const generateEventsListByProject = () => {
    return projects.map(project => {
      const projectMilestones = milestones.filter(milestone => milestone.project_id === project.id);
      const projectStartDate = projectMilestones.length > 0 ? projectMilestones[0].due_date : null;
      const projectDueDate = project.due_date;
      
      if (projectStartDate && projectDueDate) {
        return {
          start: projectStartDate,
          end: projectDueDate,
          title: `${project.name}`,
          details: `Project: ${project.id}`,
        };
      }

      return null;
    }).filter(event => event !== null);
  };

  // Example: Generate events list for the first project
  const projectEventsList = generateEventsListByProject(45);

  return (
    <div>
      <Calendar
        className="big-calendar"
        localizer={localizer}
        events={myEventsList.concat(projectEventsList)}
        start={startAccessor}
        end={endAccessor}
        titleAccessor={titleAccessor}
        detailsAccessor={detailsAccessor} // Set the custom details accessor
        style={{ height: 500 , zIndex: 900}}
        onSelectEvent={handleSelectEvent} 
        popup={true}
        showMultiDayTimes
        step={60}
      />
    </div>
  );
}

export default BigCalendar;
