
import dayjs from 'dayjs';
import React from 'react';
import Overdue from '../images/Overdue.png';
import Upcoming from '../images/Upcoming.png';
import Nonurgent from '../images/NonUrgent.png';
import { removeEventFromGoogleCalendar } from './MilestoneGoogleHelper';

export const getFlagImage = (dueDate, today, isComplete) => {
    // render the correct flag for each target depending on how close the user is to the due date 
    const daysUntilDue = dueDate.diff(dayjs(today), 'day');
    if (isComplete) {
      return <img src={Nonurgent} className='flag' alt='Longtime' />;
    } else if (daysUntilDue < 0) {
      return <img src={Overdue} className='flag' alt='Overdue' />;
    } else if (daysUntilDue < 30) {
      return <img src={Upcoming} className='flag' alt='Urgent' />;
    } else {
      return <img src={Nonurgent} className='flag' alt='Longtime' />;
    }
  };

  export const getCompletionDate = (milestone, isComplete) => {

    if (isComplete && milestone.completion_date) {
      try {
        // Ensure the date string is in the correct format (YYYY-MM-DD)
        const formattedDate = dayjs(milestone.completion_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
        
        return (
          <div className='completion-date'>
            Completed on {formattedDate}
          </div>
        );
      } catch (error) {
        console.error('Error parsing completion date:', error);
        return null; // Handle parsing error gracefully
      }
    }
    return null;
  };

export const deleteMilestone = (id, removeFromGoogleCalendar, token, milestones, setMilestones, user, setUser, googleEventId) => {
    fetch(`https://docket-backend-853a870ada67.herokuapp.com/milestones/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
        
          const updatedMilestones = milestones.filter((m) => m.id !== id);
        
          setMilestones(updatedMilestones);
          const updatedUserMilestones = user.milestones.filter((m) => m.id !== id);
          setUser({ ...user, milestones: updatedUserMilestones });

          if (removeFromGoogleCalendar && googleEventId) {
            // Call a function to remove the event from Google Calendar
            removeEventFromGoogleCalendar(googleEventId);
          }
        } else {
          throw new Error('Failed to delete milestone');
        }
      })
      .catch((error) => {
        console.error('Error deleting milestone:', error);
      });
  };

  export   const confirmDeleteMilestone = (id, token, milestones, setMilestones, user, setUser, googleEventId) => {
    if (window.confirm('Are you sure you want to delete this milestone?')) {
      const removeFromGoogleCalendar = window.confirm('Do you want to remove it from Google Calendar as well?');
      deleteMilestone(id, removeFromGoogleCalendar, token, milestones, setMilestones, user, setUser, googleEventId);
    }
  };






export const handleComplete = (milestone, milestones, setMilestones, id) => {
    const newComplete = !milestone.complete;
    const updatedData = {
      complete: newComplete,
      completion_date: dayjs().format('DD/MM/YYYY'),
    };
   

    fetch(`https://docket-backend-853a870ada67.herokuapp.com/milestones/${id}/complete`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${sessionStorage.token}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error updating milestone');
        }
        return response.json();
      })
      .then((data) => {
   
        // Milestone has been successfully marked as complete, update state:
        const updatedMilestones = milestones.map((m) => {
          if (m.id === id) {
            return { ...m, complete: newComplete, completion_date: data.completion_date };
          }
          return m;
        });
        setMilestones(updatedMilestones);
       
      })
      .catch((error) => {
        console.error('Error updating milestone:', error);
      });
  };

export const getMilestoneStatus = (dueDate, today) => {
    const dueDateStartOfDay = dayjs(dueDate).startOf('day');
    const todayStartOfDay = dayjs(today).startOf('day');
    const daysDiff = dueDateStartOfDay.diff(todayStartOfDay, 'day');

    if (daysDiff < 0) {
        return daysDiff === -1
            ? '1 day Overdue'
            : `${daysDiff * -1} days Overdue`;
    } else if (dueDateStartOfDay.isSame(todayStartOfDay, 'day')) {
        return 'Due Today';
    } else if (daysDiff === 1) {
        return '1 day remaining';
    } else {
        return `${daysDiff} days remaining`;
    }
};

