export const toggleModal = (stateVar, setStateVar) => {
    setStateVar((current) => !current);
  };
  
  export const showModal = (setIsOpen) => {
    setIsOpen((current) => !current);
  };
  
  export const showCalendar = (setCalendarOpen) => {
    setCalendarOpen((current) => !current);
  };
  
  export const showPolicy = (setPolicyOpen) => {
    setPolicyOpen((current) => !current);
  };
  

  export const showTemplates = (setTemplatesOpen) => {
    setTemplatesOpen((current) => !current);
  };
  
  export const showTemplateForm = (setTemplateFormOpen) => {
    setTemplateFormOpen((current) => !current);
  };
  
  export const showMilestoneEditForm = (setMilestone, selectedMilestone, setEditMilestoneFormOpen) => {

    setMilestone(selectedMilestone)
    setEditMilestoneFormOpen(true)
    
  };
  
  export const showUserProfile = (setUserProfileOpen) => {
    console.log('showUserProfile function called');
    console.log(setUserProfileOpen)
    setUserProfileOpen(true);
  };

  export const hideEditForm = (setEditFormOpen) => {
    setEditFormOpen(false);
  };

  export const hideMilestoneForm = (setMilestoneFormOpen) => {
    setMilestoneFormOpen(false);
  };