import { gapi } from 'gapi-script';

export const createGoogleCalendarEvent = async (milestone, iso8601DueDate, iso8601EndDate, setGoogleEventId, token) => {
  console.log(milestone)
  const event = {
    'summary':milestone.name,
    'description': milestone.project_name,
    'start': {
      'dateTime': iso8601DueDate,
      'timeZone': 'UTC',
    },
    'end': {
      'dateTime': iso8601EndDate,
      'timeZone': 'UTC',
    },
  };
  console.log('Event info: ', event)
  console.log(iso8601DueDate)
  console.log(iso8601EndDate)

  console.log('Google Calendar API Request Payload:', {
    method: 'POST',
    url: 'https://content.googleapis.com/calendar/v3/calendars/primary/events',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.google_token}`,
    },
    body: JSON.stringify({ resource: event }),
  });
  try {
    console.log('Before Google Calendar API Request');
    const response = await gapi.client.calendar.events.insert({
      calendarId: 'primary',
      resource: event,
    });
    console.log('Response:', response);
    setGoogleEventId(response.result.id);
    updateMilestoneWithGoogleEventId(milestone, token, response.result.id);
    console.log('Event added to Google Calendar:', response);
  }  catch (error) {
    console.error('Error adding event to Google Calendar:', error);
    
    if (error.result && error.result.error) {
      console.error('Google Calendar API Error:', error.result.error);
    }

    if (error.status === 401) {
      console.error('Authentication error. User is not signed in or token expired.');
    }
  }
};


export const removeEventFromGoogleCalendar = (eventId) => {
  // Check if the Google API client is loaded and authenticated
  if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
    // Make a request to the Google Calendar API to delete the event
    gapi.client.calendar.events
      .delete({
        calendarId: 'primary', // Use 'primary' for the user's primary calendar
        eventId: eventId, // The ID of the event you want to delete
      })
      .then((response) => {
        console.log('Event removed from Google Calendar:', response);
      })
      .catch((error) => {
        console.error('Error removing event from Google Calendar:', error);
      });
  } else {
    console.error('User is not signed in to Google Calendar or token has expired.');
  }
};



export const handleGoogleSignIn = async (milestone, iso8601DueDate, iso8601EndDate, setGoogleEventId, token) => {
  try {
    console.log('Initiating Google Sign-In...');
    await gapi.auth2.getAuthInstance().signIn();
    console.log('Google Sign-In successful. Creating Google Calendar Event...');
    // After successful sign-in, you can proceed to create the event.
    createGoogleCalendarEvent(milestone, iso8601DueDate, iso8601EndDate, setGoogleEventId, token);
  } catch (error) {
    console.error('Google Sign-In Error:', error);

    if (error.details) {
      console.error('Google Sign-In Error Details:', error.details);
    }
  }
};

export  const updateMilestoneWithGoogleEventId = (milestone, token, eventId) => {
  // Prepare the data to update the milestone
  const updatedData = {
    google_event_id: eventId, // Update the milestone with the Google Calendar event ID
  };
  console.log(eventId)
  // Send a PATCH request to update the milestone with the Google Calendar event ID
  fetch(`https://docket-backend-853a870ada67.herokuapp.com/milestones/${milestone.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(updatedData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error updating milestone with Google Calendar event ID');
      }
      return response.json();
    })
    .then((data) => {
      // Handle successful update of the milestone
      console.log('Milestone updated with Google Calendar event ID:', data);
    })
    .catch((error) => {
      console.error('Error updating milestone with Google Calendar event ID:', error);
    });
};
