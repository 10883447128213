import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { gapi } from 'gapi-script';
import dayjs from 'dayjs';

import Modal from 'react-bootstrap/Modal';
import Notes from './Notes.js';
import { getFlagImage, getCompletionDate,  confirmDeleteMilestone, getMilestoneStatus, handleComplete} from './MilestoneHelpers';
import { showMilestoneEditForm } from '../Homepage/ModalHelpers.js';
import { handleGoogleSignIn} from './MilestoneGoogleHelper.js'

function Milestone({
  user,
  setUser,
  milestone,
  setMilestone,
  milestones,
  setMilestones,
  setEditMilestoneFormOpen,
  today,
}) {
  const dueDate = dayjs(milestone.due_date);
  const iso8601DueDate = dayjs(milestone.due_date).format('YYYY-MM-DDTHH:mm:ssZ');
  const iso8601EndDate = dayjs(milestone.due_date).add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ');
  const isComplete = milestone.complete;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const token = sessionStorage.token;
  
  // Store the Google Calendar event ID
  const [googleEventId, setGoogleEventId] = useState(null);
 
  // Function to open the notes modal
  const openNotesModal = () => {
    setShowNotesModal(true);
  };

  // Function to close the notes modal
  const closeNotesModal = () => {
    setShowNotesModal(false);
  };
  
 
  useEffect(() => {
    async function start() {
      try {
      await gapi.client.init({
        clientId: 'process.env.GOOGLE_CLIENT_ID',
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
        scope: 'https://www.googleapis.com/auth/calendar',
        ux_mode: 'popup',
        plugin_name:'calendar',
        
      });
    } catch (error) {
      console.error('Error google', error);
    }
    }
    gapi.load('client:auth2', start);
  }, []);

  return (
    <li key={milestone.id} style={{ opacity: isComplete && '40%' }}>
      <Card className="bootstrap-card-no-hover">
        <div className="flag-container">
          {getFlagImage(dueDate, today, isComplete)}{' '}
          <p className="account-delete-button" id="milestone-notes" onClick={openNotesModal}>
            notes
          </p>
        </div>
        <br />
        <div className="card-content">
          <b className='milestone-card-name'>{milestone.name}</b>
          <br />  
          {getCompletionDate(milestone, isComplete)} {/* Render completion date when complete */}
          {milestone.complete ? (
            <p >{milestone.project_name}</p>
          ) : (
            <>
              <p className='project-names'>{milestone.project_name}   |   {dayjs(milestone.due_date).format('MM.DD.YYYY')}</p>
        
              <p className="milestone-due-date"></p>
            </>
          )}
  
          {!isComplete && (
            <p className='days-remaining'
               style={{
                 lineHeight: 0,
                 color: dayjs(milestone.due_date).diff(today, 'day') < 0 ? 'red' : 'inherit',
                 textAlign: 'left'
               }}
            >
              {getMilestoneStatus(milestone.due_date, today)}
            </p>
          )}
          <br/>

          <p onClick={() => handleComplete(milestone, milestones, setMilestones, milestone.id)} id="complete-button">
            mark complete
          </p>
        
          <div className="card-link-list" id="milestone-link-list">
          <p className="card-links" onClick={() => showMilestoneEditForm(setMilestone, milestone, setEditMilestoneFormOpen)}>
            edit
          </p>
            <p className="card-links" onClick={() => confirmDeleteMilestone(milestone.id, token, milestones, setMilestones, user, setUser, googleEventId)}>
              delete
            </p>
            <p className="account-delete-button" onClick={() => handleGoogleSignIn(milestone, iso8601DueDate, iso8601EndDate, setGoogleEventId, token)}>
              add to google
              </p>
          </div>

          <Modal show={showNotesModal} onHide={closeNotesModal}>
            <Modal.Header closeButton>
              <Modal.Title>Notes on {milestone.name}({milestone.project_name})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Notes milestone={milestone} setMilestone={setMilestone} setMilestones={setMilestones} />{/* Render your Notes component here */}
            </Modal.Body>
          
          </Modal>
        </div>
      </Card>
    </li>
  );
}

export default Milestone;
