import React, { useState } from 'react';
import Milestone from './MilestoneItem';
import Container from 'react-bootstrap/Container';

function MilestoneList({
  user,
  setUser,
  setMilestone,
  milestones,
  setMilestones,
  setEditMilestoneFormOpen,
  today,
}) {
  const [filterCriteria, setFilterCriteria] = useState('incomplete'); // Set default to 'incomplete'

  // Define the sorting function
  const sortMilestones = (criteria, milestonesToSort) => {
    if (criteria === 'dueDate') {
      // Sort by due date (ascending order)
      return [...milestonesToSort].sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
    } else if (criteria === 'name') {
      // Sort by milestone name
      return [...milestonesToSort].sort((a, b) => a.name.localeCompare(b.name));
    } else if (criteria === 'completionStatus') {
      // Sort by completion status (incomplete first)
      return [...milestonesToSort].sort((a, b) => {
        if (a.complete && !b.complete) return 1; // Completed milestones come after incomplete ones
        if (!a.complete && b.complete) return -1; // Incomplete milestones come first
        return 0; // Milestones have the same completion status
      });
    } else {
      // No sorting or default sorting logic
      return milestonesToSort;
    }
  };

  // Filter milestones based on the filter criteria
  const filterMilestones = (criteria) => {
    if (criteria === 'incomplete') {
      return milestones.filter((milestone) => !milestone.complete);
    } else if (criteria === 'completed') {
      return milestones.filter((milestone) => milestone.complete);
    } else {
      return milestones; // 'all' or any other criteria
    }
  };

  // Sort and filter milestones based on the filter criteria
  const filteredMilestones = filterMilestones(filterCriteria);
  const sortedMilestones = sortMilestones('dueDate', filteredMilestones); // Example: Sorting by due date

  const handleFilterButtonClick = (criteria) => {
    setFilterCriteria(criteria);
  };

  return (
    <div className="milestone-list">
      <div className='headline'> Targets
        <div id='milestone-options'>
          <p style={{ color: filterCriteria === 'all' ? '#4c4f51' : '#608bbb' }} className='milestone-options-button' onClick={() => handleFilterButtonClick('all')} >all</p> 
          <p style={{ color: filterCriteria === 'incomplete' ? '#4c4f51' : '#608bbb' }} className='milestone-options-button' onClick={() => handleFilterButtonClick('incomplete')}>incomplete</p>
          <p style={{ color: filterCriteria === 'completed' ? '#4c4f51' : '#608bbb' }} className='milestone-options-button' onClick={() => handleFilterButtonClick('completed')}>completed</p>
        </div>
      </div>
      <Container className="projects-container">
        {filteredMilestones.length === 0 && <h5>No milestones (yet)</h5>}
        <ul>
          {sortedMilestones.map((milestone) => (
            <div key={milestone.id}>
              <Milestone
                user={user}
                setUser={setUser}
                milestone={milestone}
                setMilestone={setMilestone}
                milestones={filteredMilestones} // Use filtered milestones here
                setMilestones={setMilestones}
                setEditMilestoneFormOpen={setEditMilestoneFormOpen}
            
                today={today}
              />
            </div>
          ))}
        </ul>
      </Container>
    </div>
  );
}

export default MilestoneList;
