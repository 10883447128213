import React, { useEffect, useState} from 'react'
import { Container, Modal, Navbar, Nav, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ProjectList from '../projects/ProjectList'
import ProjectForm from '../projects/AddProject'
import Templates from '../templates/TemplateList'
import TemplateForm from '../templates/AddTemplate'
import EditProject from '../projects/EditProject';
import EditMilestone from '../milestones/EditMilestone';
import MilestoneList from '../milestones/MilestoneList';
import BigCalendar from '../user/BigCalendar'
import UserProfile from '../user/Profile'
import PrivacyPolicy from './PrivacyPolicy';
import {showModal, showTemplates, showTemplateForm, showUserProfile, showCalendar, toggleModal, showPolicy} from './ModalHelpers'
import 'bootstrap/dist/css/bootstrap.min.css';


function HomePage() {
  const [user, setUser] = useState({});
  const [projects, setProjects] = useState([]);
  const [milestones, setMilestones] =useState([]);
  const [templates, setTemplates] = useState([]);
  const [milestone, setMilestone] = useState({});
  const [project] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const [templateFormOpen, setTemplateFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editMilestoneFormOpen, setEditMilestoneFormOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [policyOpen, setPolicyOpen] = useState(false);
  const [userProfileOpen, setUserProfileOpen] = useState(false);
  const token = sessionStorage.token;
  const navigate = useNavigate();
  const today = dayjs()
  
  

  /* retrieves index of all users, should really be a call for a specific user with user id */
  function fetchUsers()  {
    fetch(`https://docket-backend-853a870ada67.herokuapp.com/users/`, {
      method: 'GET',
      headers: new Headers( {
      Authorization: token,
      })
    })
    .then(result => result.json())
    .then(users => setterFunction(users)) 
  }
 
  /* sets state for the homepage and ensures that projects and milestones are sorted correctly, first
  by date and then by completion status */
  const setterFunction = (users) => {
    const currentUser = users.find((user) => user.email === sessionStorage.email);  
    if (!currentUser) {
      // Handle the case where the current user is not found
      console.error("Current user not found");
      return;
    }
    setUser(prevUser => ({ ...prevUser, ...currentUser }));
    const { id, projects, milestones, templates } = currentUser;
    if (!id || !projects || !milestones || !templates) {
      // Handle the case where some required data is missing
      console.error("Missing required data for user");
      return;
    }
    const sortedProjects = [...projects].sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
    const sortedMilestones = [...milestones].sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
    sortedMilestones.forEach((milestone) => {
      const project = sortedProjects.find((project) => project.id === milestone.project_id);
      milestone.project_name = project ? project.name : '';
    });
    sortedMilestones.sort((a, b) => a.complete - b.complete);
    sessionStorage.user_id = id;
    setTemplates(templates);
    setProjects(sortedProjects);
    setMilestones(sortedMilestones);
  };

  

  function onConfirmDelete() {
    alert("Your account has been deleted successfully.");
    sessionStorage.clear();
    navigate('/login'); // Now it's valid to use navigate here
  }

  

  useEffect(() => { 
    if(!sessionStorage.token) {
      navigate('/login')
    } 
    else{
      fetchUsers();
    }    
  }, []);

   
  return (
    <div className='page'>
      
      
      <Navbar  className='Navbar d-none d-lg-block' id='navbar'>
            <Container id='nav-container'>
              <img src='/2.png' className='NavBarLogo' alt='docket logo'></img>
              <Navbar.Brand >Docket</Navbar.Brand>
              <Nav >
              <Nav.Link onClick={() => showTemplates(setTemplatesOpen)}>Templates</Nav.Link>
            <Nav.Link onClick={() => showTemplateForm(setTemplateFormOpen)}>Add Template</Nav.Link>
            <Nav.Link onClick={() => showModal(setIsOpen)}>Add Project</Nav.Link>
            <Nav.Link onClick={() => showCalendar(setCalendarOpen)}>Calendar</Nav.Link>
            <Nav.Link onClick={() => showPolicy(setPolicyOpen)}>Privacy Policy</Nav.Link>
            <Nav.Link onClick={() => showUserProfile(setUserProfileOpen)} > Account</Nav.Link>
                </Nav>
                </Container>
                <div  className='logout-group'>Hello, {user.name} | <a href="/logout" className='logout-button'>Logout</a></div>
        
            
          </Navbar>

           {/* Navbar for smaller screens (less than 800px) */}
      <Navbar  className='Navbar d-lg-none' id='small-navbar'>
        {/* Adjust the content for the smaller Navbar here */}
        <img src='/2.png' className='NavBarLogo' alt='docket logo'></img>
        <Navbar.Brand>Docket</Navbar.Brand>
        {/* Add a dropdown button for additional actions */}
        <DropdownButton id="dropdown-basic-button" title="Menu">
          {/* Add dropdown items with respective actions */}
          <Dropdown.Item onClick={() => showTemplates(setTemplatesOpen)}>Templates</Dropdown.Item>
          <Dropdown.Item onClick={() => showTemplateForm(setTemplateFormOpen)}>Add Template</Dropdown.Item>
          <Dropdown.Item onClick={() => showModal(setIsOpen)}>Add Project</Dropdown.Item>
          <Dropdown.Item onClick={() => showCalendar(setCalendarOpen)}>Calendar</Dropdown.Item>
          <Dropdown.Item onClick={() => showUserProfile(setUserProfileOpen)}>Account</Dropdown.Item>
          <Dropdown.Item href="/logout">Logout</Dropdown.Item>
          {/* Include actions accordingly */}
        </DropdownButton>
      </Navbar>

       
  
          <Modal className='bootmodal'  show={isOpen} onHide={() => toggleModal(isOpen, setIsOpen)}>
            <Modal.Body><ProjectForm user={user} setUser={setUser} setProjects={setProjects} templates={templates} milestones={milestones} setMilestones={setMilestones} /></Modal.Body>
          </Modal>
  
          <Modal className='bootmodal'  show={templatesOpen} onHide={() => toggleModal(templatesOpen, setTemplatesOpen)}>
            <Modal.Body><Templates templates={templates} setTemplates={setTemplates} user={user} setTemplatesOpen={setTemplatesOpen}/></Modal.Body>
          </Modal>
  
          <Modal className='bootmodal' show={templateFormOpen} onHide={() => toggleModal(templateFormOpen, setTemplateFormOpen)}>
            <Modal.Body><TemplateForm templates={templates} setTemplates={setTemplates} user={user} setTemplateFormOpen={setTemplateFormOpen} /></Modal.Body>
          </Modal>
  
          <Modal className='bootmodal' id="edit-project"show={editFormOpen} onHide={() => toggleModal(editFormOpen, setEditFormOpen)}>
            <Modal.Body>
              <EditProject
                user={user}
                setUser={setUser}
                project={project}
                projects={projects}
                setProjects={setProjects}
                setMilestones={setMilestones}
                initialUser={user}
              />
            </Modal.Body>
          </Modal>
  
          <Modal className='bootmodal' show={editMilestoneFormOpen} onHide={() => toggleModal(editMilestoneFormOpen, setEditMilestoneFormOpen)}>
            <Modal.Body>
              <EditMilestone user={user} setUser={setUser} milestone={milestone} setMilestone={setMilestone} milestones={milestones} setMilestones={setMilestones} />
            </Modal.Body>
          </Modal>

          

          <Modal className='bootmodal' show={userProfileOpen} onHide={() => toggleModal(userProfileOpen, setUserProfileOpen)}>
            <Modal.Body>
              <UserProfile user={user} setUser={setUser} setEditFormOpen={setEditFormOpen} onConfirmDelete={onConfirmDelete}/>
            </Modal.Body>
          </Modal>

          <Modal   show={calendarOpen} onHide={() => toggleModal(calendarOpen, setCalendarOpen)}>

     
           
         <BigCalendar milestones={milestones} projects={projects}/>
          </Modal>
          <Modal   show={policyOpen} onHide={() => toggleModal(policyOpen, setPolicyOpen)} > <PrivacyPolicy /> </Modal>
  
          <br />
          <div className='project-list'>
          <p id='projects-headline'>Projects</p>
          <ProjectList user={user} setUser={setUser} projects={projects} setProjects={setProjects} milestones={milestones} setMilestones={setMilestones} templates={templates} />
          </div>
      
      
  
   
     
      <MilestoneList
       user={user} 
       setUser={setUser} 
       setMilestone={setMilestone} 
       milestones={milestones} 
       setMilestones={setMilestones}  
       setEditMilestoneFormOpen={setEditMilestoneFormOpen}
       today={today}  
       />
        <br />
        <br />
        <div>
      
      </div>
    </div>
  );
  
}
        



export default HomePage