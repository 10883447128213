import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Home from './components/pages/Homepage/HomePage';
import Logout from './components/pages/Logout';
import NotFound from './components/pages/NotFound';
import PrivacyPolicy from './components/pages/Homepage/PrivacyPolicy';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAGjusrwIsvJPd2rTa9hD5FGR2DF5e5i8A",
  authDomain: "docket-389716.firebaseapp.com",
  databaseURL: "https://docket-389716-default-rtdb.firebaseio.com",
  projectId: "docket-389716",
  storageBucket: "docket-389716.appspot.com",
  messagingSenderId: "494043831138",
  appId: "1:494043831138:web:ed4f2a1d587c6b4e82aa3b"
};



const App = () => {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);
  const [firebaseError, setFirebaseError] = useState(null);

  useEffect(() => {
    const initializeFirebase = async () => {
      try {
        // Initialize Firebase
        initializeApp(firebaseConfig);

        // Perform any additional Firebase-related tasks here
        // For example, you can check user authentication status or perform other operations

        // Set firebaseInitialized to true once Firebase is initialized
        setFirebaseInitialized(true);
      } catch (error) {
        // Handle Firebase initialization error
        console.error('Firebase initialization error:', error);
        setFirebaseError('Failed to initialize Firebase. Please try again later.');
      }
    };

    initializeFirebase();
  }, []);

  if (!firebaseInitialized) {
    // Render a loading indicator or null while Firebase is initializing
    return null;
  }

  if (firebaseError) {
    // Render an error message if Firebase initialization fails
    return (
      <div>
        <p>{firebaseError}</p>
      </div>
    );
  }

  return (
    <div>
      <GoogleOAuthProvider clientId="494043831138-f2m2q99nb0if9m034el6vp645n9sffsn.apps.googleusercontent.com">
        
      <BrowserRouter>
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/" element={<Home />} />
    <Route path="/logout" element={<Logout />} />
    <Route path="/privacy" element={<PrivacyPolicy />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
</BrowserRouter>
     
      </GoogleOAuthProvider>
    </div>
  );
};

export default App;
