import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function ProjectForm({ user, setUser, setProjects, templates, milestones, setMilestones }) {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [kind, setKind] = useState('');
  const [template, setTemplate] = useState('');
  const [formMessage, setFormMessage] = useState('');

  function handleChange(e) {
    setTemplate(e.target.value);
  }

  let handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    setName('');
    setDate('');
    setKind('');
    setTemplate('');

    fetch(`https://docket-backend-853a870ada67.herokuapp.com/users/${sessionStorage.user_id}/projects`, {
      method: 'POST',
      body: JSON.stringify({
        name: name,
        kind: kind,
        due_date: date,
        user_id: sessionStorage.user_id,
        template: template,
      }),
      headers: new Headers({
        Authorization: `${sessionStorage.token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        setFormMessage('Project created successfully.');
        return response.json();
      })
      .then((data) => {
        addProject(data);
      })
      .catch((error) => {
        console.error(error);
        setFormMessage('Error creating project.');
      });
  };

  // Filter templates to show only the ones assigned to the user
  const userTemplates = templates.filter((template) => template.user_id === user.id);

  function addProject(project) {
    console.log('Project:', project);
    setProjects((projects) => [
      ...projects,
      { id: project.id, name: project.name, due_date: project.due_date, kind: project.kind, user_id: project.userId },
    ].sort((a, b) => new Date(a.due_date) - new Date(b.due_date)));
  
    setUser((prevUser) => {
      const updatedProjects = [...prevUser.projects, project];
      return { ...prevUser, projects: updatedProjects };
    });
  
    // Use the milestones directly from the project object
    const milestonesFromProject = project.milestones;
    
    // Log the milestones for debugging
 
  console.log(milestonesFromProject)
    // Update the milestones with project-related information
   
    const updatedMilestones = milestonesFromProject.map((m) => ({
      ...m,
      project_name: project.name,
      complete: false,
      project_id: project.id,
      lead_time: parseInt(m.lead_time),
      
      due_date: dayjs(project.due_date).subtract(m.lead_time, 'day').format('MM.DD.YYYY'),
    }));
  
    console.log(project.due_date)
    console.log(dayjs(project.due_date))
    console.log(updatedMilestones)
    console.log(dayjs(project.due_date).subtract(parseInt(updatedMilestones[0].lead_time), 'day').format('MM.DD.YYYY'))
  
    setMilestones((prevMilestones) => [...prevMilestones, ...updatedMilestones].sort((a, b) =>
      new Date(a.due_date) - new Date(b.due_date)));
  
    setUser((prevUser) => {
      const updatedUserMilestones = [...prevUser.milestones, ...updatedMilestones];
      return { ...prevUser, milestones: updatedUserMilestones };
    });
  
  
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId='formProjectName'>
        <Form.Label className='project-form-label'>Name</Form.Label>
        <Form.Control
          required
          type='text'
          placeholder='Enter project name'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId='formProjectKind'>
        <Form.Label className='project-form-label'>Type of Project (You Can Also Use a Template)</Form.Label>
        <Form.Control 
        type='text'
         value={kind} 
         placeholder='ex: "Wedding Invite Suite'
         onChange={(e) => setKind(e.target.value)}>
          
         </Form.Control>
      </Form.Group>

      <Form.Group controlId='formProjectTemplate'>
        <Form.Label></Form.Label>
        <Form.Control
          as='select'
          aria-label='Default select example'
          onChange={handleChange}
          style={{ textTransform: 'capitalize' }}
        >
          <option value=''>No Template</option>
          {userTemplates.map((template) => (
            <option key={template.id} value={template.name}>
              {template.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId='formProjectDueDate'>
        <Form.Label className='project-form-label'>Due Date</Form.Label>
        <Form.Control 
        
          required
          type='date'
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </Form.Group>

      <Button variant='primary' type='submit'>
        Create Project
      </Button>

      <p>{formMessage}</p>
    </Form>
  );
}

export default ProjectForm;